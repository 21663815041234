import { PostType } from 'pn-backend';

import { SectionAlias } from '~constants';

export const getPageTitle = (layout: SectionAlias | PostType) => {
  let result;

  if (layout === SectionAlias.About) result = 'о нас';
  if (layout === SectionAlias.Articles) result = 'статьи';
  if (layout === SectionAlias.Cards) result = 'карточки';
  if (layout === SectionAlias.News) result = 'новости';
  if (layout === SectionAlias.Specs) result = 'спецпроекты';
  if (layout === SectionAlias.Tests) result = 'тесты';

  if (layout === PostType.Article) result = 'статьи';
  if (layout === PostType.Card) result = 'карточки';
  if (layout === PostType.Longread) result = 'статьи';
  if (layout === PostType.News) result = 'новости';
  if (layout === PostType.Spec) result = 'спецпроекты';
  if (layout === PostType.Test) result = 'тесты';
  if (layout === PostType.NewsFull) result = 'новости';

  return result;
};

export const getSection = (layout: PostType) => {
  let result;
  if (layout === PostType.Article) result = SectionAlias.Articles;
  if (layout === PostType.Card) result = SectionAlias.Cards;
  if (layout === PostType.Longread) result = SectionAlias.Articles;
  if (layout === PostType.News) result = SectionAlias.News;
  if (layout === PostType.Spec) result = SectionAlias.Specs;
  if (layout === PostType.Test) result = SectionAlias.Tests;
  return result;
};
